import React from 'react';
import Header from '../components/Header.js';
import Sidebar from '../components/Sidebar.js';
import ShowEvents from '../components/ShowEvents.js';
import NewEvents from '../components/NewEvents.js';
import Footer from '../components/Footer.js';
import './Dashboard.css';

function Dashboard() {
  return (
    <div className="dashboard">
      <Header />
      <div className="dashboard-content">
        <Sidebar />
        <div className="dashboard-main">
          <ShowEvents />
          <NewEvents />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Dashboard;
