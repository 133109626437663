import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../utils/firebaseConfig';

function Header() {
  const [user] = useAuthState(auth);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const showDropdown = () => {
    setDropdownVisible(true);
  };

  const hideDropdown = () => {
    setDropdownVisible(false);
  };

  return (
    <header>
      <nav className="sticky-menu">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/how-it-works">How It Works</Link>
          </li>
          <li>
            <Link to="/models">Models</Link>
          </li>
          <li>
            <Link to="/prices">Prices</Link>
          </li>
          <li>
            {user ? (
              <div
                className="user-dropdown"
                onMouseEnter={showDropdown}
                onMouseLeave={hideDropdown}
              >
                <button>
                  {user.displayName ? user.displayName : 'User'}
                </button>
                {dropdownVisible && (
                  <div className="dropdown-menu">
                    <Link to="/account">Account</Link>
                    <button onClick={() => auth.signOut()}>Log out</button>
                  </div>
                )}
              </div>
            ) : (
              <Link to="/signin" className="sign-in-button">
                Sign In
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
