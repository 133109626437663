import React, { useState } from 'react';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../utils/firebaseConfig';
import { Link, useNavigate} from 'react-router-dom';


function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleResetPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      setErrorMessage('A password reset email has been sent to your email address.');
    } catch (error) {
      setErrorMessage('Error sending password reset email: ' + error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      if (auth.currentUser.emailVerified) {
        navigate('/dashboard');
      } else {
        setErrorMessage('Please verify your email before signing in.');
      }
    } catch (error) {
      setErrorMessage('Error signing in: ' + error.message);
    }
  };

  return (
    <div>
      <h1>Sign In</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Sign In</button>
      </form>
      <button onClick={handleResetPassword}>Reset Password</button>
      {errorMessage && <p>{errorMessage}</p>}
      <Link to="/sign-up" className="signup-link">
        Don't have an account? Sign up here.
      </Link>
    </div>
  );
}

export default SignIn;
