import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../utils/firebaseConfig';
import { NavLink } from 'react-router-dom';

function Sidebar() {
  const [user] = useAuthState(auth);

  return (
    <div className="sidebar">
      {user && <h2>Welcome, {user.displayName}!</h2>}
      <div className="customer-account">
        <NavLink to="/edit-account">Edit Account</NavLink>
        <NavLink to="/my-invitations">My Invitations</NavLink>
        <NavLink to="/invoices">Invoices</NavLink>
        <button onClick={() => auth.signOut()}>Log Out</button>
      </div>
    </div>
  );
}

export default Sidebar;
