import React from 'react';
import { Link, Routes, Route } from 'react-router-dom';
import { auth } from '../utils/firebaseConfig';
import { useAuthState } from 'react-firebase-hooks/auth';
import './HomePage.css';
import Footer from '../components/Footer.js'

function HomePage() {
  const [user, loading] = useAuthState(auth);

  return (
    <div>
      <nav className="sticky-menu">
      <ul>
          <li>
            <a href="#about">Home</a>
          </li>
          <li>
            <a href="#how-it-works">How It Works</a>
          </li>
          <li>
            <a href="#models">Models</a>
          </li>
          <li>
            <a href="#prices">Prices</a>
          </li>
          {loading ? (
            <li>Loading...</li>
          ) : user ? (
            <li className="dropdown">
              <button className="user-name dropdown-toggle" id="userMenu">
                {user.displayName}
              </button>
              <div className="dropdown-content" aria-labelledby="userMenu">
                <Link to="/dashboard/account">Account</Link>
                <button
                  onClick={() => {
                    auth.signOut();
                  }}
                >
                  Log out
                </button>
              </div>
            </li>
          ) : (
            <li>
              <Link to="/sign-in" className="sign-in-button">
                Sign In
              </Link>
            </li>
          )}
        </ul>
      </nav>
      <div className="content">
        <Home />
        <HowItWorks />
        <Models />
        <Prices />
      </div>
      
      <Footer />
    </div>
  );
}

function Home() {

  return (
    <section id="about">
      <h1>RSVPready</h1>
      <p>
        RSVPready is the best way to manage your event invitations. Our online
        platform allows you to create, manage, and send beautiful invitations to
        your guests, all in one place.
      </p>
    </section>
  );
}

function HowItWorks() {
  return (
    <section id="how-it-works">
      <h2>How It Works</h2>
      <p>
        With RSVPready, you can easily create custom invitations for your event,
        track RSVPs, and manage guest lists. Our platform allows you to send
        email invitations, collect RSVPs online, and keep track of who is
        attending, all in one place.
      </p>
    </section>
  );
}

function Models() {
  return (
    <section id="models">
      <h2>Models</h2>
      <p>
        We have a range of invitation templates available to suit any event, from
        weddings and parties to business events and conferences. Whether you're
        looking for something traditional or modern, we've got you covered.
      </p>
    </section>
  );
}

function Prices() {
  return (
    <section id="prices">
      <h2>Prices</h2>
      <p>
      Our pricing is based on the number of guests you plan to invite, and
                we offer a range of pricing plans to suit any budget. Contact us
                today to find out more.
</p>
</section>
);
}

export default HomePage;
