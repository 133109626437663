import React, { useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebaseConfig';
import { useNavigate } from 'react-router-dom';

const withAuth = WrappedComponent => {
  return function (props) {
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(() => {
      if (!loading && !user) {
        navigate('/sign-in');
      }
    }, [user, loading, navigate]);

    return <WrappedComponent {...props} />;
  };
};

export default withAuth;
