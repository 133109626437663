import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyDo9A0RUoZfH_UrSuoJgA5clipIOV81ZqY",
    authDomain: "rsvpready-4d339.firebaseapp.com",
    databaseURL: "https://rsvpready-4d339-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "rsvpready-4d339",
    storageBucket: "rsvpready-4d339.appspot.com",
    messagingSenderId: "54665304548",
    appId: "1:54665304548:web:827ba019b3d3596c7a8ff1",
    measurementId: "G-LRVB41QPMY"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };
