import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../utils/firebaseConfig';

function EventInvitation() {
  const { id } = useParams();
  const [event, setEvent] = useState(null);

  useEffect(() => {
    fetchEvent();
  });
  
  const fetchEvent = async () => {
    try {
      const doc = await db.collection('events').doc(id).get();
      if (doc.exists) {
        setEvent(doc.data());
      } else {
        console.error('Event not found');
      }
    } catch (error) {
      console.error('Error fetching event:', error);
    }
  };

  return (
    <div>
      {event ? (
        <>
          <h1>{event.name}</h1>
          {/* Add more event details here */}
        </>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default EventInvitation;
