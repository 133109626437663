import React from 'react';
import { Link } from 'react-router-dom';

function EmailConfirmation() {
  return (
    <div>
      <h1>Email Confirmation</h1>
      <p>
        Thank you for signing up! A confirmation email has been sent to your
        email address. Please click the link in the email to confirm your
        account.
      </p>
      <Link to="/sign-in">Return to Sign In</Link>
    </div>
  );
}

export default EmailConfirmation;
