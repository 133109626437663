import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage.js';
import SignIn from './pages/SignIn.js';
import SignUp from './pages/SignUp.js';
import Invoices from './pages/Invoices.js';
import Dashboard from './pages/Dashboard.js';
import CreateEvent from './pages/CreateEvent.js';
import EventInvitation from './pages/EventInvitation.js';
import EmailConfirmation from './pages/EmailConfirmation.js';
import ForgotPassword from './pages/ForgotPassword.js';
import withAuth from './utils/withAuth';

const ProtectedDashboard = withAuth(Dashboard);
const ProtectedInvoices = withAuth(Invoices);
const ProtectedCreateEvent = withAuth(CreateEvent);
const ProtectedEventInvitation = withAuth(EventInvitation);

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/sign-in" element={<SignIn />} />
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/invoices" element={<ProtectedInvoices />} />
      <Route path="/dashboard" element={<ProtectedDashboard />} />
      <Route path="/create-event" element={<ProtectedCreateEvent />} />
      <Route path="/event/:id" element={<ProtectedEventInvitation />} />
      <Route path="/email-confirmation" element={<EmailConfirmation />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
    </Routes>
  );
}

export default App;
