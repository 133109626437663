import React, { useState } from 'react';
import { db } from '../utils/firebaseConfig';

function CreateEvent() {
  const [eventName, setEventName] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userId = 'YOUR_USER_ID';
      await db.collection('events').add({
        userId,
        name: eventName,
      });
      // Redirect to the Dashboard page
    } catch (error) {
      console.error('Error creating event:', error);
    }
  };

  return (
    <div>
      <h1>Create Event</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Event Name"
          value={eventName}
          onChange={(e) => setEventName(e.target.value)}
        />
        <button type="submit">Create Event</button>
      </form>
    </div>
  );
}

export default CreateEvent;
